@import "../../../scss/mixin.scss";
.nftsOuterBx{
    background: rgba(18, 18, 18, 0.7);
    padding: 25px;
    border-radius: 16px;
    margin-bottom: 30px;
}
.listOutBx{
    @include respond-to(media-xs) {
        text-align: center;
    }
}
.nftsFltr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 25px;
    h2{
        font-size: 30px;
        font-weight: 600;
        color: #fff;
    }
    .fltrBx {
        display: flex;
        @include respond-to(media-lg) {
            width: 70%;
            align-items: flex-end;
        }
        span{
            margin-right: 20px;
            label{
                display: inline-block;
                color: #b5b5b5;
                padding-right: 10px;
            }
            select{
                background: #201a31d9  url(../../../assets/images/select-Arrow.png) right 10px center no-repeat;
                border: #7d7c7c solid 1px;
                height: 40px;
                color: #fff;
                display: inline-block;
                width: 150px;
                border-radius: 0;
            }
        }
        button{
            width: 150px;
            background: transparent !important;
            color: #fff;
            height: 40px;
            font-size: 16px;
            border: #fff solid 1px;
            border-image: linear-gradient(to left, rgba(255, 214, 69, 0.7), rgba(234, 4, 173, 0.7), rgba(94, 74, 223, 0.7), rgba(82, 110, 242, 0.7), rgba(0, 237, 255, 0.7)) 1 !important;
       }
    }
}
.slideItemBx{
    width: 23.5%;
    margin-right: 2%;
    display: inline-block;
    margin-bottom: 20px;
    height: auto;
    @include respond-to(media-lg) {
        width: 32%;
        margin-right: 2%;
    }
    &:nth-child(4n){
        margin-right: 0;
        @include respond-to(media-lg) {
            margin-right: 2%;
        }
    }
    &:nth-child(3n){
        @include respond-to(media-lg) {
            margin-right: 0;
        }
    }
}
.slideItemBx{
    background: linear-gradient(217.15deg, #5062DB 2.93%, #3D3FA3 97.72%);
    border: 1px solid #686ACD;
    border-radius: 20px;
    height: 365px;
    padding: 10px;
    @include respond-to(media-lg) {
        height: auto;
    }
    .slideTitle{
        border-bottom: 1px solid #686ACD;
        padding-bottom: 15px;
        margin-bottom: 15px;
        color: #fff;
        padding-top: 10px;
        strong{
            display: block;
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
            text-transform: capitalize;
            padding-bottom: 5px;
            @include respond-to(media-lg) {
                font-size: 15px;
            }
        }
        span{
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-transform: capitalize;
        }
    }
    .likeOuter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .likeLeft{
            font-size: 18px;
            color: #fff;
            font-weight: 600;
            img{
                width: 28px;
                height: 28px;
                display: inline-block;
                vertical-align: top;
                margin-right: 8px;
            }
        }
        .likeright{
            font-size: 17px;
            color: #fff;
            svg{
                font-size: 20px;
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
}
.moreList{
    padding-top: 10px;
   button{
        width: 100%;
        border-radius: 5px;
        background: transparent !important;
        color: #fff;
        height: 50px;
        font-size: 16px;
        border: #fff solid 1px;
        border-image: linear-gradient(to left, rgba(255, 214, 69, 0.7), rgba(234, 4, 173, 0.7), rgba(94, 74, 223, 0.7), rgba(82, 110, 242, 0.7), rgba(0, 237, 255, 0.7)) 1 !important;
   }         
}