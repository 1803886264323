@import "../../../scss/mixin.scss";

.mainBg{
    background: url(../../../assets/images/dashboard-Bg.png) center center no-repeat;
    background-size: cover;
    min-height: 100vh;
}
.dash_container{
    max-width: 1500px;
    padding: 0 25px;
    margin: 0 auto;
}
.DashMidBx{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.dashRightside{
    width:calc(100% - 300px);
    @include respond-to(media-lg) {
        width: calc(100% - 265px);
    }
    .dashRightBx{
        display: flex;
        justify-content: space-between;
        align-items: flex-start; 
        .dashR_L{
            width: calc(100% - 305px);
            @include respond-to(media-lg) {
                width: calc(100% - 265px);
            }
            .graphBx_Outer {
                background: rgba(18, 18, 18, 0.7);
                padding: 25px;
                border-radius: 16px;
                margin-bottom: 30px;
            }
            .walletbalanceBx {
                display: inline-block;
                width: 42%;
                position: relative;
                padding-left: 70px;
                color: #fff;
                i{
                    background: rgba(176, 176, 176, 0.1);
                    width: 57px;
                    height: 73px;
                    border-radius: 13px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    img{
                        position:absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                span{
                    display: block;
                    font-size: 14px;
                    line-height: 17px;
                }
                strong{
                    font-size: 24px;
                    display: block;
                    line-height: 29px;
                    padding: 5px 0;
                }
                small{
                    display: block;
                    font-size: 10px;
                    img{
                        height: 12px;
                    }
                }
            }
            .balanceHd{
                padding: 30px 0;
                span{
                    display: inline-block;
                    background: rgba(0, 0, 0, 0.4);
                    border-radius: 25px;
                    width: 111px;
                    height: 55px;
                    vertical-align: middle;
                    color: #fff;
                    line-height: 55px;
                    text-align: center;
                    img{
                        height:14px;
                    }
                }
                strong{
                    display: inline-block;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    margin: 0 10px;
                    vertical-align: middle;
                }
                small{
                    font-size: 12px;
                    line-height: 14px;
                    display: inline-block;
                    vertical-align: middle;
                    color: #8ec0ff;
                    padding-left: 10px;
                    &:hover{
                        color: #e307af;
                    }
                }
            }
            .chartBx{
                padding-bottom: 30px;
                img{
                    width: 100%;
                }
            }
            .mintokenOuter{
                text-align: center;
                padding: 20px 0 0px 0;
                .mintoken{
                    padding: 20px;
                    background: rgba(176, 176, 176, 0.1);
                    border-radius: 13px;
                    color: #fff;
                    text-align: center;
                    width: 220px;
                    margin: 0 20px;
                    display: inline-block;
                    @include respond-to(media-lg) {
                        width: 150px;
                        margin: 0 10px;
                    }
                    strong{
                        display: block;
                        font-size: 18px;
                        line-height: 22px;
                        font-weight: 700;
                        padding-bottom: 15px;
                    }
                    span{
                        background: rgba(49, 53, 88, 0.8);
                        border-radius: 10px;
                        line-height: 23px;
                        text-align: center;
                        display: block;
                        margin-bottom: 15px;
                        font-size: 10px;
                    }
                    a{
                        display: inline-block;
                        width: 105px;
                        line-height: 22px;
                        background: linear-gradient(270deg, #F42BA2 3.56%, #7C0FF6 78.04%);
                        border-radius: 16px;
                        color: #fff;

                    }
                }
            }
            
        }
        .dashR_R{
            width: 285px;
            @include respond-to(media-lg) {
                width: 250px;
            }
            .gamesBx{
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                margin-bottom: 20px;
                color: #fff;
                .gamesInn{
                    width: 48%;
                    background: rgba(18, 18, 18, 0.7);
                    border-radius: 16px;
                    padding: 10px 15px;
                    text-align: center;
                    span{
                        display: block;
                        font-size: 18px;
                    }
                    strong{
                        font-size: 20px;
                    }
                }
            }
            .yourgames{
               background: rgba(18, 18, 18, 0.7);
               padding: 18px 8px;
               border-radius: 16px; 
               .gamesHd{
                    font-size: 18px;
                    color: #fff;
                    font-weight: 400;
                    padding-bottom: 10px;
                    padding-left: 15px;
                }
                .gameList{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: url(../../../assets/images/grouplistBg1.png);
                    background-size: 100% 100%;
                    color: #fff;
                    height: 87px;
                    padding: 18px 25px;
                    &:hover{
                        background: url(../../../assets/images/grouplistBg.png);
                        background-size: 100% 100%;
                    }
                    .gamesLeft{
                        font-size: 10px;
                        strong{ 
                            display: block;
                            padding-bottom: 5px;
                        }
                        span{
                            display: block;
                        }
                    }
                    .gamesRight{
                        font-size: 10px;
                        font-weight: 600;
                    }
                }
                  
            }
        }
    }
}
.transBg{
    background: rgba(0, 0, 0, 0.6);
    margin-bottom: 30px;
    .transHd{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        @include respond-to(media-lg) {
            padding-bottom: 10px;
         }
    }
    .transFltr{
        @include respond-to(media-lg) {
            text-align: right;
        }
    }
    .tableBx{
        @include respond-to(media-lg) {
            overflow: auto;
        }
        table {
            width: 100%;
            @include respond-to(media-lg) {
                min-width: 800px;
            }
            th{
                font-size: 14px;
                color: #fff;
                font-weight: 500;
                padding: 10px 20px;
                line-height: 28px;
            }
            td{
                border-top: rgba(255, 255, 255, 0.4) dashed 1px;
                padding: 10px 20px;
                color: #fff;
                font-size: 14px;
                line-height: 28px;
                a{
                    color: #94d4ff;
                }
            }
        }
    }
}
.approved{
    width: 93px;
    height: 24px;
    background: #F7FFC8;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    display: block;
    color: #7E9B08;
    text-align: center;
}
.pending{
    width: 93px;
    height: 24px;
    background: #FFF5C8;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    display: block;
    color: #E4B304;
    text-align: center;
}
.declined{
    width: 93px;
    height: 24px;
    background: rgba(255, 248, 248, 0.87);
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    display: block;
    color: #ff0000;
    text-align: center;
}
.paginationBx {
    padding: 15px 0;
    text-align: right;
    ul{
        display: inline-flex;
        li{
            margin-right: 10px;
            a {
                background: transparent !important;
                font-size: 20px;
                color: #fff !important;
                border: 0;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                padding: 0;
                display: inline-block;
            }
            &:first-child, &:last-child{
                a{
                    span{
                        font-size: 20px;
                        line-height: 0;
                    }
                }
            }
        }
        li.active{
            border: #fff solid 1px;
            border-radius: 50%;
        }
        .previous{
            a{
                // background: #2068eb !important;
                font-size: 16px;
                color: #fff;
                height: auto;
                text-align: center;
                line-height: 30px;
                padding: 0 15px;
                // border: #2068eb solid 1px;
                display: inline-block;
                width: auto;
                text-transform: capitalize;
                border-radius: 5px;
            }
        }
        // li.previous.disabled{
        //     a{
        //         opacity: 0.8;
        //     }
        // }
        .next{
            a{
                // background: #2068eb  !important;
                font-size: 16px;
                color: #fff;
                height: auto;
                text-align: center;
                line-height: 30px;
                padding: 0 15px;
                // border: #2068eb solid 1px;
                display: inline-block;
                width: auto;
                text-transform: capitalize;
                border-radius: 5px;
            }
        }
        .active{
            a.page-link {
                border: #fff solid 1px !important;
                border-radius: 50%;
            }
        }
    }
}

.modalOuter{
    .modal-content {
        background: #000;
        box-shadow: #626060 0 0 19px 1px;
        .modal-header{
            border-bottom: #2c2c2c solid 1px;
            .h4{
                color:#fff !important;
            }
            .btn-close {
                opacity: 1;
                background-color: #ffffffab;
            }
        }
        .modal-body{
            label{
                display: block;
                font-size: 18px;
                color: #fff;
                font-weight: 500;
                padding-bottom: 8px;
            }
            .gspFild {
                width: 100%;
                padding-bottom: 20px;
                input{
                    width: 100%;
                    height: 50px;
                    border: #2c2c2c solid 1px;
                    background: transparent;
                    color: #fff;
                    padding: 0 10px;
                    border-radius: 8px;
                }
            }
        }
        .modal-footer{
            border-top:#2c2c2c solid 1px;
            button{
                background: linear-gradient(270deg, #594BD9 5.64%, #5B6EED 87.41%);
                border-radius: 10px;
                width: 150px;
                height: 45px;
                color: #fff;
                font-size: 18px;
                border: 0;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }
}

.transUserOuter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    padding: 15px;
    @include respond-to(media-lg) {
        display: block;
     }
}
.srchBx{
    display: inline-block;
    vertical-align: top;
    width: 200px;
    margin-left: 10px;
    @include respond-to(media-lg) {
        width: 150px;
     }
    .mb-3 {
        margin-bottom: 0 !important;
    }
}
.transId{
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    position: relative;
    .mb-3 {
        margin-bottom: 0 !important;
        select{
            background: transparent;
            border: 0;
            border-radius: 0;
            color: #fff;
            box-shadow: none;
            height: 38px;
        }
    }
    svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        color: #fff;
    }
}
.daterange{
    display: inline-block;
    margin-left: 10px;
    width: 150px;
    .mb-3 {
        margin-bottom: 0 !important;
        .form-control{
            box-shadow: none;
        }
    }
}
.form-control{
    box-shadow: none;
}
.downBtn{
    display: inline-block;
    margin-left: 10px;
    button{
        background: transparent !important;
        padding: 0;
        border: 0;
        height: 34px;
        font-size: 24px;
    }
}
.dateInpBx{
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: 300px;
    margin-left: 10px;
    @include respond-to(media-lg) {
        width: 130px;
     }
    .dateInp{
        width: 100%;
        height: 40px;
        background: #fff;
        border-radius: 5px;
        border: 0;
        padding: 0 10px;
    }
    svg{
        position: absolute;
        right: 10px;
        top: 13px;
    }
}

.refreshBtn{
    display: inline-block;
    margin-left: 10px;
    button{
        background: transparent !important;
        border: #ccc solid 1px !important;
        height: 40px;
        font-size: 23px;
        padding: 0;
        width: 40px;
        line-height: 35px;
    }
}
.tableBx{
    overflow: auto;
}