@import "../../../scss/mixin.scss";
.collectionItemBx{
    width: 32%;
    margin-right: 2%;
    display: inline-block;
    margin-bottom: 20px;
    background: linear-gradient(217.15deg, #5062DB 2.93%, #3D3FA3 97.72%);
    border: 1px solid #686ACD;
    border-radius: 20px;
    padding: 10px;
    @include respond-to(media-lg) {
        width: 48%;
        margin-right: 4%;
    }
    @include respond-to(media-xs) {
        width: auto;
        margin-right: 0%;
    }
    &:nth-child(3n){
        margin-right: 0;
        @include respond-to(media-lg) {
            margin-right: 4%;
        }
    }
    &:nth-child(2n){
        @include respond-to(media-lg) {
            margin-right: 0;
        }
    }
    .slideImg{
        img{
            width: 100%;
        }
    }
    .collectName {
        font-size: 17px;
        font-weight: 600;
        color: #fff;
        padding: 10px 0;
    }
    .floorBx {
        display: inline-block;
        width: 49%;
        color: #fff;
        padding-bottom: 10px;
        span{
            display: block;
            font-size: 14px;
        }
        strong{
            font-size: 16px;
            font-weight: 600;
        }
    }
}