@import "../../../scss/mixin.scss";

.aftloginheader{
    padding-bottom: 50px;
}
.loginUser{
    margin-left: 0 !important;
    i{
        background: linear-gradient(90deg, #69C6E1 0%, #69C6E1 94.27%);
        width: 24px;
        height: 24px;
        display: inline-block;
        display: inline-block;
        border-radius: 50%;
        vertical-align: top;
        margin-left: 8px;
    }
    svg{
        margin-left: 5px;
        font-size: 20px;
    }
}