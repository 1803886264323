@import "../../../scss/mixin.scss";
header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
    @include respond-to(media-sm) {
        padding-top: 30px;
    }
    @include respond-to(media-sm) {
        display: block;
        padding-top: 10px;
    }
    .headLeft{
        a{
            display: inline-block;
            @include respond-to(media-sm) {
                width: 130px;
            }
            @include respond-to(media-sm) {
                margin-bottom: 15px;
            }
        }
    }
    .headRight{
        button{
            width: 200px;
            height: 40px;
            border: #fff solid 1px;
            border-radius: 5px;
            font-size: 16px;
            color: #fff;
            margin-left: 25px;
            background: transparent;
            display: inline-block;
            vertical-align: top;
            @include respond-to(media-sm) {
                width: auto;
                margin-left: 7px;
                font-size: 13px;
            }
            @include respond-to(media-sm) {
               font-size: 14px;
            }
            &:hover{
                background: #e307af;
                border: #e307af solid 1px;
            }
        }
    }
}
.navOuter{
    display: inline-block;
    @include respond-to(media-sm) {
        position: absolute;
        top: 5px;
        right: 10px;
    }

    button.Navbtn {
        width: 44px;
        height: 40px;
        border: 0 !important;
        padding: 0;
        font-size: 33px;
        line-height: 37px;
        margin-left: 10px;
        background: transparent !important;
        @include respond-to(media-sm) {
            margin-left: 3px;
        }
    }
    .NavListOuter {
        position: fixed;
        left: -250px;
        top: 0;
        bottom: 0;
        width: 250px;
        transition: all 0.5s ease-in-out;
        
        .card{
            border: 0;
            background: #000;
            border-radius: 0;
            height: 100vh;
            .NavList{
                ul{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li{
                        a{
                            display: block;
                            color: #fff;
                            padding: 15px 0;
                            border-bottom: #444 solid 1px;
                            font-size: 16px;
                            &:hover{
                                color: #e307af;
                            }
                        }
                        &:last-child{
                            a{
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .NavListOuter.collapse.show {
        left: 0;
        transition: all 0.5s ease-in-out;
    }
}
.aftLoginBtn {
    border: 1px solid #F3D453 !important;
    border-radius: 12px !important;
    height: 36px !important;
    font-size: 12px !important;
    width: auto !important;
    padding: 0 10px 0 15px;
    line-height: 24px;
    
    i{
        background: linear-gradient(90deg, #69C6E1 0%, #69C6E1 94.27%);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        margin-right: 7px;
    }
    svg{
        font-size: 16px;
    }
}