@import "../../../scss/mixin.scss";

.siderbarBg{
    background: rgba(18, 18, 18, 0.7);
    padding: 20px;
    border-radius: 16px;
    min-height: 600px;
    position: relative;
    width: 280px;
    @include respond-to(media-lg) {
        width: 250px;
    }
    .sideNav{
        ul{
            li{
                padding-bottom: 5px;
                a{
                    display: block;
                    padding: 15px 25px;
                    font-size: 18px;
                    color: #fff;
                    @include respond-to(media-lg) {
                        padding: 15px 15px;
                        font-size: 17px;
                    }
                    svg{
                        font-size: 26px;
                    }
                    &:hover{
                        background: linear-gradient(270deg, #594BD9 5.64%, #5B6EED 87.41%);
                        border-radius: 16px;
                    }
                }
                .active{
                    background: linear-gradient(270deg, #594BD9 5.64%, #5B6EED 87.41%);
                    border-radius: 16px;
                }
            }
        }
    }
    button.logoutBtn {
        position: absolute;
        left: 20px;
        bottom: 20px;
        right: 20px;
        text-align: left;
        font-size: 20px;
        background: transparent !important;
        border: 0;
        padding: 15px 25px !important;
        svg{
            font-size: 26px;
        }
    }
}