@import "../../../scss/mixin.scss";
.upldBanner{

    i {
        background: rgba(255, 255, 255, 0.15);
        border: 1px dashed #A2A2A2;
        border-radius: 10px;
        display: block;
        width: 578px;
        height: 250px;
        position: relative;
        span{
            display: block;
            font-size: 14px;
            color: #9c9c9c;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding-bottom: 0;
            text-align: center;
            font-style: normal;
            img{
                position: relative;
                left: 0;
                top: 0;
                transform: translate(0, 0);
                display: inline-block;
                padding-bottom: 10px;
            }
        }
        
    }
}
